<div class="outer-container">
    <div class="header">
        <div class="system-container">
            <div class="titleContainer contentWrapper" [ngClass]="validateSystemName() ? 'displayError': '' ">
                <form class="nameBox" [formGroup]="_systemService.systemForm" [ngClass]="validateSystemName() ? 'showError': '' ">
                    <mat-label class="nameInputBox">{{'Contacts.General.Name' | translate}}</mat-label>
                    <mat-form-field class="inputWrapper systemName" appearance="outline" floatLabel="always"
                        hideRequiredMarker="true">
                        <mat-label class="floatingLabel">{{'Contacts.General.Label' | translate}}</mat-label>
                        <input name="systemName" matInput formControlName="systemName"
                            [(ngModel)]="_systemService.systemData.systemName" autocomplete="off" maxlength="50"
                            placeholder="{{'Contacts.General.Placeholder' | translate}}" />
                        <mat-error class="errorMessage" *ngIf="_systemService.systemForm.get('systemName').hasError('required')">
                            {{'ErrorMessages.RequiredField' | translate}}
                        </mat-error>
                    </mat-form-field>
                </form>
                <form class="inputBox">
                    <mat-form-field class="inputWrapper organization" appearance="outline" floatLabel="always"
                        hideRequiredMarker="true">
                        <mat-label class="floatingLabel">{{'Common.Organization' | translate}}</mat-label>
                        <input class="organizationInput" matInput [value]="_systemService.systemData.entityName" />
                    </mat-form-field>
                </form>
            </div>
            <form class="inputBox" [formGroup]="_systemService.systemForm" [ngClass]="_systemService.systemForm.get('description').hasError('maxlength') ? 'showError displayError': '' ">
                <mat-form-field class="inputWrapper" appearance="outline" floatLabel="always">
                    <mat-label class="floatingLabel">{{'Contacts.General.Description' | translate}}</mat-label>
                    <textarea name="description" matInput [(ngModel)]="_systemService.systemData.description" formControlName="description"
                        placeholder="{{'Contacts.General.Description_placeholder' | translate}}"></textarea>
                    <mat-error class="errorMessage" *ngIf="_systemService.systemForm.get('description').hasError('maxlength')">
                        {{'Contacts.General.ErrorMessages.ExceededMaxCharactersLength' | translate}}
                    </mat-error>
                </mat-form-field>
            </form>
            <div class="contactContainer">
                <div class="contactPeronDetailsWrapper">
                    <div class="contact" [ngClass]="validatePersonName()? 'displayError': '' ">
                        <form class="inputBox contactForm" [formGroup]="_systemService.systemForm" [ngClass]="validatePersonName() ? 'showError': '' ">
                            <mat-label class="nameInputBox">{{'Contacts.Contact.Name' | translate}}</mat-label>
                            <mat-form-field class="inputWrapper" appearance="outline" floatLabel="always" hideRequiredMarker="true">
                                <mat-label class="floatingLabel">{{'Contacts.Contact.Label' | translate}}</mat-label>
                                <input name="contactPersonName" matInput formControlName="personName"
                                    [(ngModel)]="_systemService.systemData.contactPersonName" autocomplete="off"
                                    placeholder="{{'Contacts.Contact.Placeholder' | translate}}" />
                                <mat-error class="errorMessage" *ngIf="_systemService.systemForm.get('personName').hasError('required')">
                                    {{'ErrorMessages.RequiredField' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </form>
                    </div>
                    <div class="contact" [ngClass]="validatePersonEmail() ? 'displayError': '' ">
                        <form class="inputBox contactForm" [formGroup]="_systemService.systemForm" [ngClass]="validatePersonEmail() ? 'showError': '' ">
                            <mat-form-field class="inputWrapper" appearance="outline" floatLabel="always" hideRequiredMarker="true">
                                <mat-label class="floatingLabel">{{'Contacts.Email.Label' | translate}}</mat-label>
                                <input name="contactPersonEmail" matInput formControlName="personEmail"
                                    [(ngModel)]="_systemService.systemData.contactPersonEmail" autocomplete="off"
                                    placeholder="{{'Contacts.Email.Placeholder' | translate}}" />
                                <mat-error class="errorMessage" *ngIf="_systemService.systemForm.get('personEmail').hasError('required')">
                                    {{'ErrorMessages.RequiredField' | translate}}
                                </mat-error>
                                <mat-error class="errorMessage" *ngIf="_systemService.systemForm.get('personEmail').hasError('email')">
                                    {{'ErrorMessages.Email' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </form>
                    </div>
                </div>
                <div class="statusContainer">
                    <mat-label class="nameInputBox">{{'Contacts.Status.Name' | translate}}</mat-label>
                    <app-slider class="slider" inactiveLable="{{'Contacts.Status.Disabled'| translate}}"
                        activeLable="{{'Contacts.Status.Activated'| translate}}" (sliderChange)="onSliderChange($event)"
                        [checked]="_systemService.systemData.active"></app-slider>
                </div>
            </div>
        </div>
    </div>
    <div class="dialogActionsContainer">
        <button cdkFocusInitial class="activeButton" (click)="navigateToDetails()">{{'Common.Next'| translate}}</button>
    </div>
</div>
