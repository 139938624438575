import { Component } from '@angular/core';
import { SystemDataService } from '../system-data.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent {

  constructor(public _systemService: SystemDataService) { }

  isValidatePublicKeyForm() {
    return this._systemService.systemForm.get('sshKey').hasError('required') &&
      (this._systemService.systemForm.controls['sshKey'].touched || this._systemService.systemForm.controls['sshKey'].dirty) ||
      this._systemService.systemForm.get('sshKey').hasError('format');
  }

  showError() {
    return this._systemService.systemForm.invalid && this._systemService.systemForm.touched;
  }
}
