import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { CommonAppModule } from './common.app.module';
import { SharedDetailsModule } from './share-details/shared-details.module';

import { AppComponent } from './app.component';
import { DialogBoxComponent } from './dialogs/dialog-box/dialog-box.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MyshareComponent } from './myshare/component/myshare.component';
import { BaseAbstractComponent } from './base-abstract/base-abstract.component';
import { AuthConfigACMModule } from './auth/auth-config-acm.module';
import { AuthConfigDefaultModule } from './auth/auth-config.module';
import { CallbackComponent } from './callback/callback.component';
import { SelectBoxComponent } from './select-box/select-box.component';
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { QuickShareComponent } from './sidebar/quick-share/quick-share.component';
import { GroupShareComponent } from './sidebar/group-share/group-share.component';
import { environment } from 'src/environments/environment';
import { DefaultUrlSerializer, UrlSerializer } from "@angular/router";
import { AppTranslationModule } from './app-translation.module';
import { DynamicComponentDirective } from './directives/dynamic-component.directive';
import { ThrottleClickDirective } from './directives/app-prevent-double-click.directive';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { ReplaceFileDialogComponent } from './dialogs/replace-file-dialog/replace-file-dialog.component';
import { DownloadFileDialogComponent } from './dialogs/download-file-dialog/download-file-dialog.component';
import { CommonUIModule } from './common-ui/common-ui.module';
import { ServiceLocator } from './types/service-locator';
import { RemovedFromShareDialogComponent } from './dialogs/removed-from-share-dialog/removed-from-share-dialog.component';
import { SignOutDialogComponent } from './dialogs/sign-out/sign-out-dialog.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TransferComponent } from './sidebar/transfer/component/transfer.component';
import { SessionInterceptor } from './http/interceptors/session/session.interceptor';
import { ErrorInterceptor } from './http/interceptors/error/error.interceptor';
import { AllshareComponent } from './allshare/component/allshare.component';
import { DeleteSystemDialogComponent } from './dialogs/delete-system-dialog/delete-system-dialog.component';
import { AddPersonDialogComponent } from './dialogs/add-person/add-person.component';
import { RemoveFileTypeRestrictionDialogComponent } from './dialogs/remove-file-type-restriction-dialog/remove-file-type-restriction-dialog.component';
import { RenameFileDialogComponent } from './dialogs/rename-file-dialog/rename-file-dialog.component';
import { InviteCodeComponent } from './invite-code/invite-code.component';
import { DeleteExternalDialogComponent } from './dialogs/delete-external-dialog/delete-external-dialog.component';
import { NoRightsComponent } from './no-rights/no-rights.component';
import { ContactsModule } from './contacts/contacts.module';

let dev = [
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  HttpClientModule,
  MaterialModule,
  SharedDetailsModule,
  CommonAppModule,
  AppTranslationModule,
  SharedPipesModule,
  InfiniteScrollModule,
  CommonUIModule,
  NgxFileDropModule,
  ContactsModule
]

if(environment.isTest) {
  dev.push(AuthConfigACMModule)
} else {
  dev.push(AuthConfigDefaultModule)
}

@NgModule({
  declarations: [
    AppComponent,
    DialogBoxComponent,
    LandingPageComponent,
    ToolbarComponent,
    SidebarComponent,
    MyshareComponent,
    AllshareComponent,
    BaseAbstractComponent,
    CallbackComponent,
    SelectBoxComponent,
    DeleteDialogComponent,
    AddPersonDialogComponent,
    QuickShareComponent,
    GroupShareComponent,
    DynamicComponentDirective,
    ThrottleClickDirective,
    ReplaceFileDialogComponent,
    RenameFileDialogComponent,
    DeleteSystemDialogComponent,
    DeleteExternalDialogComponent,
    DownloadFileDialogComponent,
    RemovedFromShareDialogComponent,
    SignOutDialogComponent,
    NoRightsComponent,
    TransferComponent,
    RemoveFileTypeRestrictionDialogComponent,
    InviteCodeComponent
  ],
  imports: dev,
  providers: [
    { provide: UrlSerializer, useClass: DefaultUrlSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = injector;
  }
}
