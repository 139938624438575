<div class="outer-container">
    <div class="header">
        <form class="inputBox" style="opacity: 0.38;">
            <mat-label class="nameInputBox">{{'Contacts.Details.SystemDetails.Name' | translate}}</mat-label>
            <mat-form-field class="inputWrapper" appearance="outline" floatLabel="always">
                <mat-label class="floatingLabel">{{'Contacts.Details.SystemDetails.Label' | translate}}</mat-label>
                <input name="username" matInput [(ngModel)]="_systemService.systemData.azureId" autocomplete="off" disabled="true"
                    placeholder="{{'Contacts.Details.SystemDetails.Placeholder' | translate}}" />
            </mat-form-field>
        </form>
        <form class="inputBox" [formGroup]="_systemService.systemForm" [ngClass]="isValidatePublicKeyForm() ? 'showError': '' ">
            <mat-form-field class="inputWrapper" appearance="outline" floatLabel="always" hideRequiredMarker="true">
                <mat-label class="floatingLabel">{{'Contacts.Details.PublicKey.Label' | translate}}</mat-label>
                <textarea name="sshKey" matInput formControlName="sshKey" [(ngModel)]="_systemService.systemData.sshKey"
                     placeholder="{{'Contacts.Details.PublicKey.Placeholder' | translate}}"></textarea>
                <mat-error class="errorMessage" *ngIf="_systemService.systemForm.get('sshKey').hasError('required')">
                    {{'ErrorMessages.RequiredField' | translate}}
                </mat-error>
                <mat-error class="errorMessage" *ngIf="_systemService.systemForm.get('sshKey').hasError('format')">
                    {{'Contacts.Details.PublicKey.ErrorMessages.IncorrectFormat' | translate}}
                </mat-error>
            </mat-form-field>
        </form>
        <div *ngIf="showError()" class="additionalErrorMessage">
            {{'Contacts.Error' | translate}}
        </div>
    </div>
    <div class="dialogActionsContainer">
        <button cdkFocusInitial class="activeButton" (click)="_systemService.saveSystemDetails()">
            {{'Common.Save'| translate}}</button>
    </div>
</div>
