import { Component, OnInit } from '@angular/core';
import { TabManagerService, SysteTabs } from 'src/app/services/tab-manager.service';
import { ContactOperation } from 'src/app/types/participant.model';
import { SystemDataService } from '../system-data.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {

  constructor(private _tabManagerService: TabManagerService, public _systemService: SystemDataService) { }

  ngOnInit() {
    this._systemService.initialiseValidators();
  }

  navigateToDetails(): void {
    this._tabManagerService.updateTab(SysteTabs.Details);
  }

  isEditOperation(): boolean {
    return this._systemService.systemData.systemOperation === ContactOperation.edit;
  }

  onSliderChange(value: boolean) {
    this._systemService.systemData.active = value;
  }

  validateSystemName() {
    return this._systemService.systemForm.get('systemName').hasError('required') && (this._systemService.systemForm.controls['systemName'].touched || this._systemService.systemForm.controls['systemName'].dirty);
  }

  validatePersonName() {
    return this._systemService.systemForm.get('personName').hasError('required') && (this._systemService.systemForm.controls['personName'].touched || this._systemService.systemForm.controls['personName'].dirty);
  }

  validatePersonEmail() {
    return (this._systemService.systemForm.get('personEmail').hasError('required') || this._systemService.systemForm.get('personEmail').hasError('email')) && (this._systemService.systemForm.controls['personEmail'].touched || this._systemService.systemForm.controls['personEmail'].dirty);
  }
}
