import { NgModule } from '@angular/core';
import { AddContactComponent } from './add-contact/add-contact.component';
import { SystemComponent } from './system/system.component';
import { DetailsComponent } from './details/details.component';
import { AppTranslationModule } from '../app-translation.module';
import { CommonAppModule } from '../common.app.module';
import { CommonModule } from '@angular/common';
import { ContactRoutingModule } from './contact-routing.module';
import { MaterialModule } from '../material.module';
import { CommonUIModule } from '../common-ui/common-ui.module';
import { AddUserContactComponent } from './component/user/add-user/add-user.component';
import { SearchUserComponent } from './component/user/search-new-user/search-user.component';
import { UserTabComponent } from './user-tab/user-tab.component';
import { SystemTabComponent } from './system-tab/system-tab.component';
import { ContactsComponent } from './contacts.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ExternalContactTabComponent } from './external-contact-tab/external-contact-tab.component';

@NgModule({
    declarations: [
        AddContactComponent,
        SystemComponent,
        DetailsComponent,
        AddUserContactComponent,
        SearchUserComponent,
        UserTabComponent,
        SystemTabComponent,
        ExternalContactTabComponent,
        ContactsComponent
    ],
    imports: [
        CommonModule,
        CommonAppModule,
        AppTranslationModule,
        ContactRoutingModule,
        MaterialModule,
        CommonUIModule,
        InfiniteScrollModule
    ]
})
export class ContactsModule { 
}
